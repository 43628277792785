@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxP.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc9.ttf) format('truetype');
}
:root {
  --primary: #2cabe3;
  --success: #2cd07e;
  --info: #2cabe3;
  --warning: #ffc36d;
  --danger: #ff5050;
  --light: #f7fafc;
  --dark: #343a40;
  --dhl: rgba(209, 253, 202, 0.2);
  --fedex: rgba(235, 243, 254, 0.47);
  --rincos: rgba(255, 223, 140, 0.14);
}
.ant-btn-background-ghost:focus,
.ant-btn-background-ghost:hover {
  color: #ffffff !important;
  border-color: transparent !important;
}
.ant-btn-basic {
  background-color: #e4e8ed;
  color: #656565;
  border: none !important;
}
.ant-btn-basic:hover {
  background-color: #c9cdd2;
  color: #656565 !important;
  border: none !important;
}
.ant-btn-basic:active {
  background-color: #e4e8ed;
  color: #656565 !important;
  border: none !important;
}
button:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: #f5f5f5 !important;
}
button:disabled:hover {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
}
.ant-table-body {
  overflow-x: auto !important;
}
.ant-drawer-body {
  padding: 0 !important;
}
.box__body {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.box__body .header-box {
  display: flex;
  z-index: 1;
}
.box__body .box__content {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  overflow: hidden;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
@media (max-width: 1200px) {
  .box__body .box__content {
    width: 100vw;
  }
}
.box__body .box__content .content-box {
  flex: 1 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 70px;
}
@media (max-width: 1200px) {
  .box__body .box__content .content-box {
    width: 100vw;
  }
}
.title-page {
  font-size: 18px;
  font-weight: bold;
}
#nprogress .bar {
  background: #31b2da;
}
#nprogress .peg {
  box-shadow: 0 0 10px #31b2da, 0 0 5px #31b2da;
}
#nprogress .spinner-icon {
  border-top-color: #31b2da;
  border-left-color: #31b2da;
}
.ant-modal-body {
  padding-top: 0 !important;
}
.ant-modal-confirm .ant-modal-body {
  padding-top: 32px!important;
}
.error-field-select,
.error-field-input {
  border-left: 5px solid #f5222d !important;
  transition: all 0.3s;
}
.error-field-radio {
  border-left: 5px solid #f5222d !important;
  padding-left: 10px !important;
}
.error-field-select:hover > .ant-select-selector {
  border: none;
}
@-webkit-keyframes scaleAnimation {
  0%,
  50% {
    scale: 1.25;
  }
  100% {
    scale: 1;
  }
}
@keyframes scaleAnimation {
  0%,
  50% {
    scale: 1.25;
  }
  100% {
    scale: 1;
  }
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 14px;
  font-family: "Poppins", "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  font-size: 14px;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}
input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}
/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}
/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}
/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none;
}
/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */
}
/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted;
}
/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0;
}
/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
}
/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}
/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0;
}
/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */
}
/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */
}
/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}
/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}
/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */
}
/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}
/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */
}
/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box;
}
/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html,
button,
input,
select,
textarea {
  color: #222;
}
::selection {
  background: #b3d4fc;
  text-shadow: none;
}
img {
  vertical-align: middle;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
textarea {
  resize: vertical;
}
.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
.bold {
  font-weight: bold;
}
.uppercase {
  text-transform: uppercase;
}
.color-success {
  color: #2cd07e !important;
  color: var(--success) !important;
}
.border-success {
  border-color: #2cd07e !important;
  border-color: var(--success) !important;
}
.bg-success {
  background-color: #2cd07e !important;
  background-color: var(--success) !important;
}
.color-warning {
  color: #ffc36d !important;
  color: var(--warning) !important;
}
.border-warning {
  border-color: #ffc36d !important;
  border-color: var(--warning) !important;
}
.bg-warning {
  background-color: #ffc36d !important;
  background-color: var(--warning) !important;
}
.color-info {
  color: #2cabe3 !important;
  color: var(--info) !important;
}
.border-info {
  border-color: #2cabe3 !important;
  border-color: var(--info) !important;
}
.bg-info {
  background-color: #2cabe3 !important;
  background-color: var(--info) !important;
}

